<template>
  <div class="edit-account" v-loading="loading">
    <el-collapse v-model="activePanels">
      <el-collapse-item name="form">
        <template slot="title">
          <div class="collapse-title">
            <h6>Account Information</h6>
          </div>
        </template>
        <AccountDetailsForm :accountInformation="accountInformation" v-model="accountFormData" />
      </el-collapse-item>
      <el-collapse-item title="Abilities" name="abilities">
        <template slot="title">
          <div class="collapse-title">
            <h6>Abilities</h6>
          </div>
        </template>
        <AbilitiesCheckboxes
          class="mt-2"
          :accountInformation="accountInformation"
          v-model="accountAbilitiesData"
        />
      </el-collapse-item>
      <el-collapse-item name="quote-modes">
        <template slot="title">
          <div class="collapse-title">
            <h6>Active Quote Modes</h6>
            <i
              class="ion ion-information-circle-outline"
              v-b-popover.hover.top="
                'This section manages the modes with which this account can quote'
              "
            ></i>
          </div>
        </template>
        <QuoteModes
          class="mt-2"
          :accountInformation="accountInformation"
          v-model="quoteModesData"
        />
      </el-collapse-item>
    </el-collapse>
    <div name="children-accounts">
      <div class="collapse-title">
        <h6>Children Accounts</h6>
        <i
          class="ion ion-information-circle-outline"
          v-b-popover.hover.top="
            'Add children accounts to grant load visibility to the parent account'
          "
        ></i>
      </div>
      <ChildrenAccounts
        ref="childrenAccounts"
        :childrenAccounts="childrenAccounts"
        :accountInformation="accountInformation"
        @getChildrenListToUpdate="getChildrenListToUpdate"
      />
    </div>
    <div class="edit-account-toolbar">
      <button
        @click="$emit('closeModal')"
        class="edit-account-toolbar__cancel edit-account-toolbar__buttons"
      >
        <i class="ion ion-close-outline"></i>{{ $t("accountManagement.cancel") }}
      </button>
      <button class="edit-account-toolbar__save edit-account-toolbar__buttons" @click="saveHandler">
        <i class="ion ion-save-outline"></i>{{ $t("accountManagement.save") }}
      </button>
    </div>
  </div>
</template>

<script>
import { modifyModesEnablement } from "@/views/QuoteBook/modes";
/* import resetDefaultSettings from "@/utils/resetDefaultSettings.json"; */
import AccountDetailsForm from "./AccountDetailsForm.vue";
import AbilitiesCheckboxes from "./AbilitiesCheckboxes.vue";
import QuoteModes from "./QuoteModes.vue";
import ChildrenAccounts from "./ChildrenAccounts/ChildrenAccounts.vue";

export default {
  name: "EditAccountDetails",
  props: {
    // accountInformation: Object,
    accountId: Number,
  },
  components: {
    AccountDetailsForm,
    AbilitiesCheckboxes,
    QuoteModes,
    ChildrenAccounts,
  },
  data() {
    return {
      activePanels: ["form", "abilities", "quote-modes", "children-accounts"],
      accountFormData: {},
      accountAbilitiesData: {},
      quoteModesData: {},
      payloadToUpdate: null,
      changes: {},
      accountInformation: {},
      accountInformationCopy: {},
      childrenAccounts: null,
      accountChildrenUpdateList: null,
      loading: false,
    };
  },
  computed: {
    modifiedAccountIsConnectedAccount() {
      const connectedAccount = this.$store.getters["user/getMyInfo"];
      return connectedAccount.accounts.some(
        (item) => item.account_id === this.accountInformation.account_id
      );
    },
  },
  async created() {
    this.loading = true;
    this.accountInformation = await this.getAccountById(this.accountId);
    this.childrenAccounts = await this.getAllChildrenAccounts();
    this.accountInformationCopy = {
      account_name: this.accountInformation.account_name,
      account_id: this.accountInformation.account_id,
      can_dispatch: this.accountInformation.can_dispatch,
      can_dispatch_exp: this.accountInformation.can_dispatch_exp,
      can_dispatch_flatbed: this.accountInformation.can_dispatch_flatbed,
      can_dispatch_ftl: this.accountInformation.can_dispatch_ftl,
      can_dispatch_insurance: this.accountInformation.can_dispatch_insurance,
      can_dispatch_ltl: this.accountInformation.can_dispatch_ltl,
      can_dispatch_reefer: this.accountInformation.can_dispatch_reefer,
      can_dispatch_drayage: this.accountInformation.can_dispatch_drayage,
      is_active: this.accountInformation.is_active,
      is_branded: this.accountInformation.is_branded,
      show_cost: this.accountInformation.show_cost,
      website: this.accountInformation.website,
    };
    this.loading = false;
  },
  methods: {
    async getAccountById(id) {
      if (this.$options.filters.checkPermission("get:account")) {
        const response = await this.$store.dispatch("account/getAccountById", id);
        return response;
      }
      return null;
    },
    async getAllChildrenAccounts() {
      const response = await this.$store.dispatch("accountChildren/getAllChildrenAccounts", {
        account_id: this.accountId,
      });
      return response;
    },
    getChildrenListToUpdate(dataList) {
      this.accountChildrenUpdateList = dataList;
    },
    toggleEditMode() {
      this.$emit("toggleEditMode");
    },
    async saveHandler() {
      if (!this.$options.filters.checkPermission("patch:account")) {
        return;
      }
      const childrenAccountsValidation = this.$refs.childrenAccounts.validateMissingInformation();
      if (!childrenAccountsValidation) {
        return;
      }
      this.$emit("updateAccount", true);
      await this.$refs.childrenAccounts.childrenActionHandler();
      const payload = {
        id: this.accountInformation.id,
        body: {
          ...this.accountFormData,
          ...this.accountAbilitiesData,
          ...this.quoteModesData.payload,
          email_notify: this.$refs.childrenAccounts.emailNotify,
        },
      };
      await this.updateAccountInformation(payload);
      this.$emit("updateAccount", false);
      this.toggleEditMode();
      this.$emit("closeModal");
    },
    async updateAccountInformation(payload) {
      const formatedPayload = await this.accountInformationFieldsChanged(payload);
      formatedPayload.body.children_accounts = this.accountChildrenUpdateList;
      const isUpdatedAccount = await this.saveUpdatedAccount(formatedPayload);
      if (this.modifiedAccountIsConnectedAccount) {
        this.updateLocalStorageSettings(payload);
      }
      if (isUpdatedAccount) {
        this.swal({
          title: "Success!",
          text: "Account updated successfully",
          icon: "success",
        });
      }
    },
    updateLocalStorageSettings(payload) {
      let userSettings = JSON.parse(localStorage.getItem("user-settings"));
      userSettings = this.updateQuoteModes(userSettings);
      userSettings.can_dispatch = payload.can_dispatch;
      userSettings.show_cost = payload.show_cost;
      this.$store.commit("login/modifyTokenInfo", {
        can_dispatch: payload.can_dispatch,
        show_cost: payload.show_cost,
      });
      localStorage.setItem("user-settings", JSON.stringify(userSettings));
    },
    updateQuoteModes(userSettings) {
      modifyModesEnablement(this.quoteModesData.completeData);
      Object.values(this.quoteModesData.completeData).forEach((mode) => {
        userSettings.can_dispatch_modes[mode.id] = mode.enabled;
      });
      return userSettings;
    },
    async accountInformationFieldsChanged(payload) {
      const payloadToSend = { id: payload.id, body: {} };
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(payload.body)) {
        if (this.accountInformationCopy[key] !== value) {
          payloadToSend.body[key] = value;
        }
      }
      return payloadToSend;
    },
    async saveUpdatedAccount(payload) {
      let isUpdatedAccount = false;
      if (payload.body.is_branded === false) {
        const updateAccount = await this.swal({
          title: "Are you sure you want to remove the branding from this account?",
          text: "This will reset all styles and account settings. ",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        });
        if (updateAccount) {
          isUpdatedAccount = true;
          await this.vuexUpdateAccount(payload);
        } else {
          this.accountInformation.is_branded = this.accountInformationCopy.is_branded;
          isUpdatedAccount = false;
        }
      } else {
        isUpdatedAccount = true;
        await this.vuexUpdateAccount(payload);
      }
      return isUpdatedAccount;
    },
    async vuexUpdateAccount(payload) {
      await this.$store.dispatch("account/updateAccount", payload);
      this.$store.commit("account/updateAccount", payload);
    },
    async deleteAccount() {
      if (!this.$options.filters.checkPermission("delete:account")) {
        return;
      }
      const deleteAccount = await this.swal({
        title: "Are you sure?",
        text: "This account will be permanently deleted. This action is irreversible.",
        icon: "warning",
        buttons: ["Cancel", "Delete"],
        dangerMode: true,
      });
      if (deleteAccount) {
        this.$emit("deleteAccount");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-account {
  position: relative;
  padding-top: 5px;
}

.collapse-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $color-primary-company;

  h6 {
    margin: 0px;
    text-align: start;
    font-weight: bold;
    font-size: 18px;
  }

  i {
    font-size: 25px;
  }
}

.edit-account-toolbar {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 15px;
  @include font-button-text;

  &__save {
    @include primary-button;

    & i {
      margin-right: 2px;
    }
  }

  &__cancel {
    @include secondary-button;
  }

  &__buttons {
    margin: 0px;
    width: 150px;
  }
}

.delete-button {
  @include font-standart-text;

  &__trigger {
    text-align: left;
    color: $color-remove-inactive-button;
    cursor: pointer;
    font-weight: bold;
  }
}

::v-deep .el-collapse-item__header:nth-child(1) {
  border-bottom: 1px solid $color-border-container;
}
</style>
